import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Modal,
  Input,
  Tooltip,
  Avatar,
  Badge,
  Drawer,
  Select,
  Popover,
  Switch
} from 'antd';
import { Box } from '@mui/material';
import { BsFillHeartFill, BsHeart } from 'react-icons/bs';
import { FaShoppingCart } from "react-icons/fa";
import { CiCircleMinus, CiCirclePlus } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import { IoBagCheckOutline } from "react-icons/io5";

import infoIcon from '../../assets/images/infoicon-blue.svg';

import Table from '../../component/table/Index'
import SearchInput from '../../component/searchInput/Index';
import ToasterNotification from '../../component/alert';
import Notification from "../../component/notifications/notification";

import {
  GetGiftCards,
  GetPaymentMethods,
  MarkAsFavouriteGiftCard,
  SetGiftCardState
} from '../../redux/slices/gift-card-slice';

import { StoresStyleWrapper } from './style';
import { debounce, startCase } from 'lodash';
import Spin  from '../../component/Spin';

const Stores = () => {
  const dispatch = useDispatch();

  const {
    loading,
    giftCards,
    paymentMethods,
    success,
    message,
    markedIsFavourite
  } = useSelector(state => state.giftCard);

  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedBrand, setSelectedBrand] = useState('');
  const [sortObj, setSortObj] = useState({
    sortColumn: 'brandName',
    sortType: 'asc'
  });
  const [cartModal, setCartModal] = useState(false);
  const [tableRows, setTableRows] = useState([]);
  const [modalTableRows, setModalTableRows] = useState([]);
  const [activeButtonId, setActiveButtonId] = useState(null);
  const [buttonTexts, setButtonTexts] = useState({});
  const [cartDrawer, setCartDrawer] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [quantity, setQuantity] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [deletedId, setDeletedId] = useState('');
  const [paymentMethodOptions, setPaymentMethodOptions] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('CreditCard');
  const [stockFilter, setStockFilter] = useState(false);
  const [isBetaUser, setIsBetaUser] = useState(true);

  const handleToggle = (giftCardId, isFavourite) => {
    dispatch(MarkAsFavouriteGiftCard({
      giftCardId,
      isFavourite: !isFavourite
    }));
  };

  const handleModalRows = (cards) => {
    setSelectedBrand(cards[0].brandName);
    const data = cards.map((obj) => {
      const {
        value,
        discount,
        quantityAvailable
      } = obj;

      let stock = !quantityAvailable ? 'Out of stock' : quantityAvailable;
      if (quantityAvailable > 10 && quantityAvailable <= 100) {
        stock = '10+ available';
      } else if (quantityAvailable > 100) {
        stock = '100+ available';
      } else if (quantityAvailable > 0) {
        stock = `${quantityAvailable} available`;
      }

      const price = value - (value * discount);

      return {
        originalObj: { ...obj, price },
        value: `$${value.toFixed(2)}`,
        price: `$${price.toFixed(2)}`,
        discount: `${(discount * 100).toFixed(2)}% Off`,
        delivery: 'Electronic',
        status: stock
      }
    });

    setModalTableRows(data);
  };

  const handleClick = (params) => {
    const clickedId = params._id
    setActiveButtonId(clickedId);

    setButtonTexts(prevTexts => ({
      ...prevTexts,
      [clickedId]: 'Item Added'
    }));

    setTimeout(() => {
      setActiveButtonId(null);
      setButtonTexts(prevTexts => ({
        ...prevTexts,
        [clickedId]: 'Add To Cart'
      }));
    }, 2000);

    const selectedQuantity = quantity.find(item => item._id === params._id)?.quantity;
    if (selectedQuantity > 0) {
      setCartItems((prevItems) => {
        const existingItemIndex = prevItems.findIndex(item => item._id === params._id);

        if (existingItemIndex !== -1) {
          const updatedItems = [...prevItems];
          updatedItems[existingItemIndex].quantity = updatedItems[existingItemIndex].quantity + selectedQuantity;
          return updatedItems;
        } else {
          return [...prevItems, { ...params, _id: params._id, quantity: selectedQuantity }];
        }
      });

      setQuantity((prevItems) => {
        const existingItemIndex = prevItems.findIndex(item => item._id === params._id);

        if (existingItemIndex !== -1) {
          const updatedItems = [...prevItems];
          updatedItems[existingItemIndex].quantity = 0
          return updatedItems;
        }
      });
    }
  };

  const handleQuantityChange = (_id, quantity) => {
    setQuantity((prevItems) => {
      const existingItemIndex = prevItems.findIndex(item => item._id === _id);

      if (existingItemIndex !== -1) {
        const updatedItems = [...prevItems];
        updatedItems[existingItemIndex].quantity = quantity;
        return updatedItems;
      } else {
        return [...prevItems, { _id, quantity }];
      }
    });
  };

  const handleIncrement = (params) => {
    setCartItems((prevItems) => {
      const existingItemIndex = prevItems.findIndex(item => item._id === params._id);

      if (existingItemIndex !== -1) {
        const updatedItems = [...prevItems];
        updatedItems[existingItemIndex].quantity = updatedItems[existingItemIndex].quantity + 1;
        return updatedItems;
      }
    });
  };

  const handleDecrement = (params) => {
    setCartItems((prevItems) => {
      const existingItemIndex = prevItems.findIndex(item => item._id === params._id);

      if (existingItemIndex !== -1) {
        const updatedItems = [...prevItems];
        const currentQuantity = updatedItems[existingItemIndex].quantity;

        if (currentQuantity > 1) {
          updatedItems[existingItemIndex].quantity = currentQuantity - 1;
          return updatedItems;
        } else {
          return prevItems;
        }
      }
      return prevItems;
    });
  };

  const columnDefinition = [{
    headerName: 'Store',
    field: 'store',
    flex: 1,
    minWidth: 550,
    resizable: true,
    cellRenderer: (params) => {
      return (
        <Box display="flex" alignItems="center" gap={2}>
          <p onClick={() => handleToggle(params?.data?._id, params?.data?.isFavourite)}>
          {!params?.data?.isFavourite ? <BsHeart style={{ color: 'red' }}  /> : <BsFillHeartFill style={{ color: 'red' }} />}
         </p>
          <Popover placement='topLeft' content={
            <StoresStyleWrapper>
              <div className='card-ui-wrapper'>
                <div className='val'>
                  <p>Card Value</p>
                  <p>{params.data.cardValue}</p>
                </div>
                <div className='val'>
                  <p>Discount Rate</p>
                  <p>{params.data.price}</p>
                </div>
              </div>
            </StoresStyleWrapper>
          }>
            <p className='store-name' onClick={() => handleModalRows(params.data.cards)}>{params.data.store}</p>
          </Popover>
        </Box>
      )
    }
  }, {
    headerName: 'Status',
    field: 'status',
    flex: 1,
    minWidth: 140,
    resizable: true
  }];

  const cartTableColumnDefinition = [{
    headerName: 'Brand Name',
    field: 'store',
    minWidth: 100,
    flex: 1,
    resizable: true,
    cellRenderer: (params) => <span className='store-name'>{params.data.brandName}</span>
  }, {
    headerName: 'Price($)',
    field: 'price',
    minWidth: 100,
    flex: 1,
    resizable: true,
    cellRenderer: (params) => {
      let { price } = params.data;

      if (selectedPaymentMethod === "AchDebit" || selectedPaymentMethod === "Zelle") {
        price *= 0.97;
      }

      return (
        <div className='quantity-buttons-wrapper'>
          {(price).toFixed(2)}
        </div>
      )
    }
  }, {
    headerName: 'Quantity',
    field: 'quantity',
    minWidth: 100,
    flex: 1,
    resizable: true,
    cellRenderer: (params) => {
      return (
        <div className='quantity-buttons-wrapper'>
          <CiCircleMinus onClick={() => handleDecrement(params.data)} />
          <span>{params.data.quantity}</span>
          <CiCirclePlus onClick={() => handleIncrement(params.data)} />
        </div>
      )
    }
  }, {
    headerName: 'Total',
    field: 'total',
    minWidth: 100,
    flex: 1,
    resizable: true,
    cellRenderer: (params) => {
      const { value, discount, quantity } = params.data;

      let price = value - (value * discount);

      if (selectedPaymentMethod === "AchDebit" || selectedPaymentMethod === "Zelle") {
        price *= 0.97;
      }

      return (
        <div className='quantity-buttons-wrapper'>
          {(price * quantity).toFixed(2)}
        </div>
      )
    }
  }, {
    headerName: 'Actions',
    field: 'action',
    minWidth: 100,
    flex: 1,
    resizable: true,
    cellRenderer: (params) => {
      return (
        <div className='delete-buttons-wrapper'>
          <MdDelete onClick={() => { setIsDelete(true); setDeletedId(params.data._id) }} />
        </div>
      )
    }
  }];

  const actionCellRenderer = (params) => {
    const [localQuantity, setLocalQuantity] = useState(
      quantity?.find(item => item._id === params.data.originalObj._id)?.quantity || 0
    );

    const handleLocalChange = (value) => {
      setLocalQuantity(value);
      handleQuantityChange(params.data.originalObj._id, value);
    };

    return (
      <div>
        <Input
          type="number"
          min={0}
          max={100}
          value={localQuantity}
          onChange={(e) => handleLocalChange(Number(e.target.value))}
        />
        {
          params.data.originalObj.quantityAvailable !== 0 && (
            <Tooltip title="Item Added" placement='top' trigger="click">
              <Button
                onClick={() => { handleClick(params.data.originalObj); setLocalQuantity(0); }}
                className={activeButtonId === params.data.originalObj._id ? 'active-class' : 'default-class'}
                disabled={localQuantity ? false : true}
              >
                {buttonTexts[params.data.originalObj._id] || 'Add To Cart'}
              </Button>
            </Tooltip>
          )
        }
      </div>
    )
  };

  const modalTableColumnDefinition = [{
    headerName: 'Value',
    field: 'value',
    minWidth: 140,
    resizable: true,
    flex: 1,
  }, {
    headerName: 'Price',
    field: 'price',
    minWidth: 140,
    resizable: true,
    flex: 1,
  }, {
    headerName: 'Discount (Credit Card)',
    field: 'discount',
    resizable: true,
    flex: 1,
    minWidth: 170
  }, {
    headerName: 'Delivery',
    field: 'delivery',
    resizable: true,
    flex: 1,
    minWidth: 140
  }, {
    headerName: 'Status',
    field: 'status',
    resizable: true,
    flex: 1,
    minWidth: 140
  }, {
    headerName: '',
    field: '',
    minWidth: 350,
    resizable: true,
    flex: 1,
    cellRenderer: actionCellRenderer
  }];

  const defaultColDefs = {
    resizable: true,
    sortable: true
  };

  useEffect(() => {
    if (giftCards?.length) {
      const data = giftCards.map((obj, index) => {
        const {
          _id,
          brandId,
          isFavourite = false,
          brandName: store,
          cards,
          minValue,
          maxValue,
          minDiscount,
          maxDiscount,
          status
        } = obj;

        const cardValue = cards?.length > 1 ? `$${minValue} - $${maxValue}` : `$${minValue}`;
        const price = cards?.length > 1
          ? (minDiscount === maxDiscount)
            ? `${(minDiscount * 100).toFixed(2)}%`
            : `${(minDiscount * 100).toFixed(2)}% - ${(maxDiscount * 100).toFixed(2)}%`
          : `${(minDiscount * 100).toFixed(2)}%`;

        return {
          _id,
          id: index,
          store,
          cardValue,
          price,
          cards,
          status,
          brandId,
          isFavourite
        }
      });

      setTableRows(data);
    } else {
      setTableRows([]);
    }
  }, [giftCards]);

  useEffect(() => {
      dispatch(GetGiftCards({
        searchKeyword,
        sortColumn: sortObj.sortColumn,
        sortType: sortObj.sortType,
        stockFilter
      }));
  }, [sortObj.sortColumn, sortObj.sortType, searchKeyword, stockFilter]);

  useEffect(() => {
    dispatch(GetPaymentMethods());
  }, []);

  useEffect(() => {
    if (paymentMethods.length) {
      const newMethods = paymentMethods.map(({ type, discount }) => ({
        value: type,
        label: discount ? `${startCase(type)} (+${discount * 100}% discount )` : startCase(type)
      }))

      setPaymentMethodOptions(newMethods)
    }
  }, [paymentMethods]);

  useEffect(() => {
    if (success && message) {
      if (markedIsFavourite) {
        Notification({
          type: "success",
          title: "Favourite",
          description: message
        });
      }

      dispatch(GetGiftCards({
        searchKeyword,
        sortColumn: sortObj.sortColumn,
        sortType: sortObj.sortType,
        stockFilter
      }));

      dispatch(SetGiftCardState({
        field: 'message',
        value: ''
      }));
      dispatch(SetGiftCardState({
        field: 'success',
        value: false
      }));
    }
  }, [success, message]);

  const onSortChanged = ({ api: { sortController } }) => {
    const sortModel = sortController.getSortModel();
    if (sortModel.length > 0) {

      const { colId: sortColumn, sort: sortType } = sortModel[0];
      let sColumn;
      if (sortColumn === 'store') {
        sColumn = 'brandName';
      } else if (sortColumn === 'cardValue') {
        sColumn = 'value'
      } else if (sortColumn === 'price') {
        sColumn = 'discount';
      }

      setSortObj({
        sortColumn: sColumn,
        sortType
      });
    }
  };

  const onFilterChanged = (filterModel) => {
  };

  const onSelectionChanged = (selectedRows) => {
  };

  const isRowSelectable = (rowNode) => {
    return true;
  };

  const handleChange = debounce((value) => {
    setSearchKeyword(value);
  }, 1000);

  const handleDelete = () => {
    const updatedItems = [...cartItems];
    const filterItems = updatedItems.filter((item) => item._id !== deletedId)

    setCartItems(filterItems);
    setDeletedId('');
    setIsDelete(false);
  };

  const totalCartValue = cartItems.reduce((total, item) => {
    const price = item.value - (item.value * item.discount);
    return total + (price * item.quantity);
  }, 0).toFixed(2);

  const finalCartValue = selectedPaymentMethod === "AchDebit" || selectedPaymentMethod === "Zelle" 
  ? (totalCartValue * 0.97).toFixed(2) 
  : totalCartValue;

  const generateCartUrl = () => {
    console.log('\n process.env.CARD_CENTER_CART', process.env.CARD_CENTER_CART);
    const baseUrl = process.env.CARD_CENTER_CART;
    const itemParams = cartItems.map((item, index) => {
      return `items[${index}].brand=${encodeURIComponent(item.brandId)}&items[${index}].value=${encodeURIComponent(item.value)}&items[${index}].quantity=${encodeURIComponent(item.quantity)}&items[${index}].originalRate=${encodeURIComponent(item.originalRate)}&items[${index}].paymentMethodType=${encodeURIComponent(selectedPaymentMethod)}`;
    });

    const checkoutUrl = `${baseUrl}?${itemParams.join('&')}`;
    console.log('\n ** checkoutUrl **', checkoutUrl);

    return checkoutUrl;
  };

  const handleCheckout = () => {
    if (!selectedPaymentMethod) {
      Notification({
        type: "error",
        title: "Error",
        description: "Select any payment method"
      });

      return;
    }

    setCartItems([]);
    setCartDrawer(false);
    window.open(generateCartUrl(), '_blank');
  };

  const handlePaymentMethodChange = (value) => {
    setSelectedPaymentMethod(value);
  };

  const CheckoutHeaderDrawer = () => (
    <StoresStyleWrapper>
      <div className='header-drawer'>
        <b>Cart Items</b>
        <Select placeholder="Select payment method" value={selectedPaymentMethod} options={paymentMethodOptions} onChange={handlePaymentMethodChange} />
      </div>
    </StoresStyleWrapper>
  );

  return (
    <StoresStyleWrapper>
      <div className="table-box">
      {isBetaUser && 
        <ToasterNotification
          onClose={() => setIsBetaUser(false)}
          variant="danger"
          color={true}
          content={
            <p>
              The special gift card discount only applies to new Card Center sign-ups.
              {/* Please Click <a href="https://chrome.google.com/webstore/detail/qik-finds/hakjiagadepdajkkhjcplkdinlkgakal" target='_blank'> Here</a> to install it. */}
            </p>
          }
          icon={infoIcon}
        />
      }
        <div className="box-table-ui-wrapper">
          <div className="box-table-ui-wrapper-items">
          <header>
            <h1 className="page-title">Gift Cards</h1>
            <div className="filter-search">
              <SearchInput
                placeholder="Search Stores"
                className="store-search"
                onChange={(e) =>
                  handleChange(e.target.value)
                }
              />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingRight: '15px'
                }}
              >
                <span style={{ color: 'black' }}>All</span>
                <Switch
                  style={{ marginInline: '10px', borderRadius: '20px', }}
                  size='large'
                  onChange={(e) => setStockFilter(e)}
                />
                <span style={{ color: 'black' }}>In Stock</span>
              </div>
            </div>
          </header>
            <Table
              height='150px'
              headerHeight={50}
              columnDefinitions={columnDefinition}
              defaultColDef={defaultColDefs}
              rowSelection='multiple'
              onSortChange={onSortChanged}
              rowData={tableRows}
              rowHeight={67}
              onFilterChanged={onFilterChanged}
              onSelectionChanged={onSelectionChanged}
              isRowSelectable={isRowSelectable}
            />
          {loading && <Spin />}
          </div>
          <div className="box-table-ui-wrapper-items new-table-for-brand">
          <div className="filter-search modal-table-filter-wrapper">
            <Badge style={{ background: 'red' }} onClick={() => setCartDrawer(true)} count={cartItems.reduce((total, item) => total + item. quantity, 0)}>
                <Avatar shape="square" size="large"><FaShoppingCart /></Avatar>
              </Badge>
             <p className='heading-wrapper'>{selectedBrand || 'Select Brand'}</p>
              </div>
            <Table
              height='150px'
              headerHeight={50}
              columnDefinitions={modalTableColumnDefinition}
              defaultColDef={modalTableRows}
              rowSelection='multiple'
              onSortChange={onSortChanged}
              rowData={modalTableRows}
              rowHeight={67}
              onFilterChanged={onFilterChanged}
              onSelectionChanged={onSelectionChanged}
              isRowSelectable={isRowSelectable}
            />
          </div>
        </div>
        <Modal open={cartModal} width={1100} footer={null} closable={false}>
          <StoresStyleWrapper>
            <div className='brand-header'>
              <h6>{selectedBrand}</h6>
              <Button onClick={() => setCartDrawer(true)}>Go to Cart</Button>
            </div>
            <div className="modal-table-box">
              <Table
                height={'500px'}
                headerHeight={50}
                columnDefinitions={modalTableColumnDefinition}
                defaultColDef={modalTableRows}
                rowSelection='multiple'
                onSortChange={onSortChanged}
                rowData={modalTableRows}
                rowHeight={67}
                onFilterChanged={onFilterChanged}
                onSelectionChanged={onSelectionChanged}
                isRowSelectable={isRowSelectable}
              />
            </div>
            <Button className='button-close' onClick={() => { setCartModal(false); setQuantity([]); }}>Close</Button>
          </StoresStyleWrapper>
        </Modal>
        <Drawer open={cartDrawer} closable onClose={() => setCartDrawer(false)} width={800} placement='right' title={<CheckoutHeaderDrawer />}>
          <StoresStyleWrapper className='drawer-wrapper-store'>
            <Table
              height={'500px'}
              headerHeight={50}
              columnDefinitions={cartTableColumnDefinition}
              defaultColDef={modalTableRows}
              rowSelection='multiple'
              onSortChange={onSortChanged}
              rowData={cartItems}
              rowHeight={67}
              onFilterChanged={onFilterChanged}
              onSelectionChanged={onSelectionChanged}
              isRowSelectable={isRowSelectable}
            />
            <div className='drawer-footer-wrapper'>
              <div className='amount-wrapper'>
                <h2>Total Amount</h2>
                <p>${finalCartValue}</p>
              </div>
              <Button onClick={handleCheckout} disabled={cartItems.length === 0}>Checkout <IoBagCheckOutline /></Button>
            </div>
          </StoresStyleWrapper>
        </Drawer>
        <Modal wrapClassName='modal-delete-wrapper' footer={null} closable={false} open={isDelete}>
          <StoresStyleWrapper>
            <div className='modal-inner-wrapper'>
              <MdDelete />
              <p>Are you sure you want to remove this</p>
              <p>This action cannot be undo</p>
            </div>
            <footer>
              <Button onClick={() => setIsDelete(false)}>Cancel</Button>
              <Button onClick={() => handleDelete()}>Delete</Button>
            </footer>
          </StoresStyleWrapper>
        </Modal>
      </div >
    </StoresStyleWrapper>
  )
}

export default Stores;
